
import React, { useEffect, useMemo, useRef, useState } from "react"
import { history } from "../../../index";
import { useParams } from "react-router-dom";
import { get, httpDelete } from "repsitory/generic_repository";
import { Title } from "components/barbagli/text";
import { useIntl } from "react-intl";
import { LoaderComponent } from "components/loading_widget";
import { DatePickerInput } from "components/datepicker";
import * as B from '../../../components/barbagli/select';
import moment from "moment";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { OutlineButton } from "components/barbagli/common";
import { confirmAlert } from "react-confirm-alert";

const ShowTitle = (props: any) => {
    const intl = useIntl();
    if (props.waterBalance) {
        return <>
            {intl.messages["water_balance"]}: {props.waterBalance.name} {intl.messages["condominium"]}: {props.waterBalance.condominiumName} ({props.waterBalance.condominiumAddress} {props.waterBalance.condominiumCity})
        </>
    } return <></>
}

const checkDay = (period: string) => {
    switch (period) {
        case "Giornaliero":
            return "day"
        case "Mensile":
            return "month"
        case "Settimanale":
            return "week"
    }
}

const CustomTooltip = (props: any) => {
    if (props && props.payload) {
        const obj = props.payload[0]
        debugger
        if (obj) {
            if (obj.payload.value) {
                return <div style={{
                    background: "#ebedef",
                    padding: 5
                }}>
                    Conguaglio: {obj.payload.value.toFixed(2)} <br />
                    {moment(obj.payload.balanceDate).format("DD/MM/yyyy")} <br />
                    Interpolato: {obj.payload.interpolated == true ? "Si" : "No"} <br />
                    Calcolato su tutti i contatori: {obj.payload.all_meters == true ? "Si" : "No"}
                </div>
            } else {
                debugger
                return <div style={{
                    background: "#ebedef",
                    padding: 5
                }}>
                    {moment(obj.payload.balanceDate).format("DD/MM/yyyy")} <br />
                    <span style={{ color: props.payload[0].color }}> Valore in ingresso: {props.payload[0].value.toFixed(2)}</span><br />
                    <span style={{ color: props.payload[1].color }}>Valore in uscita: {props.payload[1].value.toFixed(2)} </span> <br />
                    <span style={{ color: props.payload[0].color }}>Interpolato in ingresso: {obj.payload.in_interpolated == true ? "Si" : "No"}</span> <br />
                    <span style={{ color: props.payload[1].color }}>Interpolato in uscita: {obj.payload.out_interpolated == true ? "Si" : "No"} </span><br />
                    Calcolato su tutti i contatori: {obj.payload.all_meters == true ? "Si" : "No"}

                </div>
            }
        }
    }
    return null;
};

export function MagliaIdricaShow() {
    const [waterBalance, setWaterBalance] = useState()
    const [dateFrom, setDateFrom] = useState(moment(new Date()).subtract(25, "days").toDate())
    const [dateTo, setDateTo] = useState(new Date())
    const [data, setData] = useState<any>()
    const [dataMax, setDataMax] = useState<any>('dataMax')
    const [balacnce, setBalance] = useState<any>([])
    const [diffBalacnce, setDiffBalance] = useState<any>([])
    const [concatenateBalacnce, setConcatenateBalance] = useState<any>([])
    const [period, setPeriod] = useState("Giornaliero")
    const params: any = useParams();
    const intl = useIntl();
    const ref = useRef<any>(null);

    useEffect(() => {
        get(`/api/v1/water_balance/${params?.id}`).then((r) => {
            setWaterBalance(r.data.attributes)
            setDataMax(r.data.attributes.adjustment)
        })
    }, [])

    useEffect(() => {
        if (data) {
            setBalance(calcultateBalacne(data))
            setDiffBalance(calcultateDiffBalacne(data))
            setConcatenateBalance(calculateConcatenateBlance(data))
        }
    }, [data])

    useEffect(() => {
        get(`/api/v1/water_balance/${params?.id}/graph?from=${dateFrom.toISOString()}&to=${dateTo.toISOString()}&interval=${checkDay(period)}`).then((r) => {
            if (r.data && r.data.attributes)
                setData(r.data.attributes)
        })
    }, [waterBalance])

    const calcultateBalacne = (data: any) => {
        return data.inValues.map((inVal: any, index: BigInteger) => {
            const outVal = data.outValues.find((outVal: any) => outVal.balacne_date == inVal.balacne_date)

            if (outVal) {
                return { balanceDate: inVal.balacne_date, value: ((inVal.total - outVal.total) / inVal.total), interpolated: inVal.interpolated, all_meters: inVal.all_meters }
            } else {
                return null
            }
        }).filter(function (el: any) {
            return el != null;
        });
    }

    const calcultateDiffBalacne = (data: any) => {
        return data.inValues.map((inVal: any, index: BigInteger) => {
            const outVal = data.outValues.find((outVal: any) => outVal.balacne_date == inVal.balacne_date)

            if (outVal) {
                return { balanceDate: inVal.balacne_date, value: (inVal.total - outVal.total), interpolated: inVal.interpolated, all_meters: inVal.all_meters }
            } else {
                return null
            }
        }).filter(function (el: any) {
            return el != null;
        });
    }
    const calculateConcatenateBlance = (data: any) => {
        return data.inValues.map((inVal: any, index: BigInteger) => {
            const outVal = data.outValues.find((outVal: any) => outVal.balacne_date == inVal.balacne_date)

            if (outVal) {
                return {
                    balanceDate: inVal.balacne_date, inValue: inVal.total, outValue: outVal.total, in_interpolated: inVal.interpolated,
                    out_interpolated: outVal.interpolated, all_meters: inVal.all_meters
                }
            } else {
                return null
            }
        }).filter(function (el: any) {
            return el != null;
        });
    }

    return <div>
        <div className="row ">
            <div className="col-md-12">
                <Title> <ShowTitle waterBalance={waterBalance} /></Title>
            </div>
        </div>
        <div className="row ">
            <div className="col-md-12">
                {waterBalance == null && <div style={{ marginTop: 30 }}><LoaderComponent width={24} height={24} /></div>}
            </div>
        </div>
        <div className="row" style={{ marginBottom: 40, marginTop: 40 }}>
            <div className="col-md-3">

                <B.Select
                    enabled={true}
                    label={intl.messages["period"]?.toString().toUpperCase()}
                    isMulti={false}
                    valueMapper={(v) => v.replace("\"", "")}
                    availableOptions={["Giornaliero", "Settimanale", "Mensile"]}
                    selectedOptions={[period]}

                    onChange={([type]) => {
                        return setPeriod(type);
                    }}
                />
            </div>
            <div className="col-md-3">
                <DatePickerInput style={{ marginBottom: 12, flexGrow: 1, marginLeft: 2 }} value={dateFrom} onChange={setDateFrom} enabled label={intl.messages["reading_date_before"].toString()} />
            </div>
            <div className="col-md-3">
                <DatePickerInput style={{ marginBottom: 12, flexGrow: 1, marginLeft: 2 }} value={dateTo} onChange={setDateTo} enabled label={intl.messages["reading_date_before"].toString()} />
            </div>
            <div className="col-md-3">
                <button className={"outline-button dashboard-editor-icon"} style={{ marginTop: 15, float: "right" }} onClick={() => {
                    get(`/api/v1/water_balance/${params?.id}/graph?from=${dateFrom.toISOString()}&to=${dateTo.toISOString()}&interval=${checkDay(period)}`).then((r) => {
                        setData(r.data.attributes)
                    })
                }}>{intl.messages["search"]}</button>
            </div>
        </div>
        <div className="row ">
            <div className="col-md-12" ref={ref}>

                <LineChart
                    width={ref.current ? ref.current.offsetWidth : 600}
                    height={400}
                    data={balacnce}
                    margin={{
                        top: 5,
                        right: 100,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="balanceDate" tickFormatter={(props: any) => {
                        return moment(props).format("DD/MM/yyyy")
                    }} />
                    <YAxis domain={['dataMin', dataMax + 5]} tickFormatter={(props: any) => {
                        if (props != Infinity) {
                            return props.toFixed(2)
                        }
                        return props
                    }} />
                    <Legend />
                    <Tooltip content={<CustomTooltip />} />
                    <ReferenceLine y={dataMax} label="% conguaglio" stroke="red" />

                    <Line type="monotone" name="Conguaglio" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />

                </LineChart>

            </div>
        </div>
        <div className="row ">
            <div className="col-md-12">

                <LineChart
                    width={ref.current ? ref.current.offsetWidth : 600}
                    height={400}
                    data={diffBalacnce}
                    margin={{
                        top: 5,
                        right: 100,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="balanceDate" tickFormatter={(props: any) => {
                        return moment(props).format("DD/MM/yyyy")
                    }} />
                    <YAxis tickFormatter={(props: any) => {
                        if (props != Infinity) {
                            return props.toFixed(2)
                        }
                        return props


                    }} />
                    <Legend />
                    <Tooltip content={<CustomTooltip />} />


                    <Line type="monotone" name="Differenza conguaglio" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />

                </LineChart>

            </div>
        </div>
        <div className="row ">
            <div className="col-md-12">

                <LineChart
                    width={ref.current ? ref.current.offsetWidth : 600}
                    height={400}
                    data={concatenateBalacnce}
                    margin={{
                        top: 5,
                        right: 100,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="balanceDate" tickFormatter={(props: any) => {
                        return moment(props).format("DD/MM/yyyy")
                    }} />
                    <YAxis tickFormatter={(props: any) => {
                        if (props != Infinity) {
                            return props.toFixed(2)
                        }
                        return props


                    }} />
                    <Legend />
                    <Tooltip content={<CustomTooltip />} />


                    <Line type="monotone" name="Ingresso" dataKey="inValue" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" name="Uscita" dataKey="outValue" stroke="#f9b115" activeDot={{ r: 8 }} />

                </LineChart>

            </div>
        </div>
        <DeleteButton waterBalance={waterBalance} onDelete={(id: any) => {

            httpDelete(`/api/v1/water_balance/${id}`).then(() =>
                history.replace('/maglia_idrica')
            )
        }
        } intl={intl.messages} />
    </div>
}

function DeleteButton(props: any) {


    return <OutlineButton style={{ flexGrow: 1 }} onClick={() => {
        confirmAlert({
            title: props.intl["confirm"].toString(),
            message: props.intl["cominium_deletion_confirm"].toString(),
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        props.onDelete(props.waterBalance.id)
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }}>{props.intl["delete_water_balance"]}</OutlineButton>


}