import { type } from "os";
import { fallbackMapCenter } from "..";
import { GeoJSONPoint, geoJsonPointFromCoordinates } from "./geo_json";
import { Group } from "./group";

export interface Attributes {
  height?: number;
  volume: number;
  lastAnomalyAt?: string
  condominium?: string;
  subCondominium?: string;
  immobile?: string;
  updatedAt: Date;
  lastErrorAt?: string;
  state: string;
  anomalies?: any;
  class?: string;
  serialNumber?: string;
  contract: string;
  description: string;
  currentKey: string;
  fwVersion: string;
  initialReading: string;
  rfConfig: string;
  rfInterface: string;
  valve: string;
  rssi?: number;
  pdr: string;
  meterTypeId?: any;
  manufacturer: string;
  longitude: number;
  ldn: string;
  coordinates?: GeoJSONPoint
  latitude: number;
  lastSeen: Date;
  lastCommission?: Date;
  insertedAt: Date;
  id?: number;
  metrologicalError: any,
  type: string,
  installDate: string,
  mechanicalSerialNumber: string,
  systemError: any,
  deviceTypeId: string;
  battery?: number;
  address: string;
  activeKeys: string;
  active: string;
  identifier: string;
  updateGroups: string[];
  deleteGroups: string[];
  listGroups: string[];
  condominiumId?: string | number,
  subCondominiumId?: string,
  immobileId?: string,
  meta?: any,
  meterHeatDivider?: MeterHeatDivider
  activeAlarms?: Record<string, {
    alarm_opened_at: string
    last_alarm_time: string
  }>
}

export type MeterHeatDivider = {
  room: string
  kq: number | undefined
  thermal_power: number | undefined
  k: number | undefined
  type: "normal" | "external"
  kc: number | undefined
  C: number | undefined
  height_mm: number | undefined
  width_mm: number | undefined
  depth_mm: number | undefined
  description_key: string | undefined
  conversion_factor: number | undefined
  k_update_from: string | undefined
}

export const emptyMeterHeatDivider: MeterHeatDivider = {
  room: "",
  kq: undefined,
  kc: undefined,
  height_mm: undefined,
  width_mm: undefined,
  depth_mm: undefined,
  k: undefined,
  C: undefined,
  k_update_from: (new Date()).toISOString(),
  thermal_power: undefined,
  description_key: undefined,
  type: "normal",
  conversion_factor: undefined
}


export interface Meter {
  type: string;
  id: string;
  attributes: Attributes;
  groups: Group[];
}

export const emptyMeter: () => Meter = () => ({
  type: "meter",
  id: "",
  groups: [],
  attributes: {
    volume: 0,
    updatedAt: new Date(),
    coordinates: geoJsonPointFromCoordinates(fallbackMapCenter)!,
    contract: "",
    valve: "",
    state: "",
    type: "AF",
    mechanicalSerialNumber: "",
    installDate: (new Date()).toISOString(),
    serialNumber: "",
    identifier: "",
    pdr: "",
    meterTypeId: "",
    manufacturer: "",
    longitude: 11.866667,
    ldn: "",
    latitude: 45.416668,
    lastSeen: new Date(),
    lastCommission: new Date(),
    insertedAt: new Date(),
    metrologicalError: [],
    systemError: [],
    deviceTypeId: "",
    address: "",
    activeKeys: "",
    active: "",
    description: "",
    currentKey: "",
    fwVersion: "",
    initialReading: "",
    rfConfig: "",
    rfInterface: "",
    updateGroups: [],
    deleteGroups: [],
    listGroups: []
  }
});