import React, { useState } from "react"
import { useIntl } from "react-intl";
import { SearchBar } from "../../../components/barbagli/search_bar";
import { SortableTableView } from "../../../components/barbagli/sortable_table_view";
import { FilterParam, OrderParam } from "../../../models/barbagli/filter_order";
import { fetchPaginatedCollection } from "../../../repsitory/generic_repository";
import {history} from "../../../index";
import { deepCopy } from "../../../utils/deep_copy";
import moment from "moment";

type Props = {
    onClick?: (m: any) => void
    prexistingFilters?: FilterParam[]
}

type Alarm = any;

export function AlarmsIndex(props: Props) {
    const [order, setOrder] = useState<OrderParam>({column: "date", order: "desc"});
    const [filter, setFilter] = useState<FilterParam[]>([]);
    const intl = useIntl();
    return <div className="d-flex flex-column">
        <SearchBar filters={filter} avaliableFilters={["meter_identifier", "name", "condominium", "sub_condominium", "immobile"]} 
          filterNamePostProcessor={(id) => intl.messages[id]?.toString() ?? id}
          onFiltersChange={setFilter}
         />
        <SortableTableView
            key={JSON.stringify(filter)}
            perPage={8}
            fetchCollection={
               async (page, perPage) => {
                   return fetchPaginatedCollection<Alarm>(
                       `/api/v1/alarm?sort=${order.column}&order=${order.order}${[...filter, ...(props.prexistingFilters ?? [])].map(f => `&${f.column}=${f.value}`).join("")}`)
                       (page, perPage);
                }
            }
            onTap={
                (item: any | string) => {
                    // console.log(item)
                    if(props.onClick && typeof item != "string") {
                        props.onClick(item)
                        } else {
                        if(typeof item !== "string") history.push(`/alarms/${item.name ?? item.identifier ?? item.id}`, item) 
                    }
                }
            }
            translated
            itemsPreFormatter={(c: Alarm) => { 
                const cc: any = deepCopy(c);
                delete cc["id"];
                delete cc["condominiumId"];
                delete cc["subCondominiumId"];
                delete cc["immobileId"];
                delete cc["updatedAt"];
                delete cc["readingId"];
                cc["note"] = (cc["meta"]?.notifications ?? []).length > 0
                delete cc["meta"];
               
                cc["date"] = moment(cc["date"]).format("DD/MM/YYYY HH:mm:ss")
                cc["alarmOpenedAt"] = moment(cc["alarmOpenedAt"]).format("DD/MM/YYYY HH:mm:ss")
                cc["alarmClosedAt"] = cc["alarmClosedAt"] ? moment(cc["alarmClosedAt"]).format("DD/MM/YYYY HH:mm:ss") : "--"
                
                delete cc["insertedAt"]
                delete cc["meterIdentifier"];
                // delete cc["altiorStatus"];
                return cc;
            }}
            order={order}
            onOrder={setOrder}
            orderableColumns={[
                "date", "alarm_opened_at", "serial_number", "sub_condominium", "immobile" 
            ]}
        /> 

    </div>
}
