import React from "react";

import * as H from 'history'
import { post, put, putStatusCode, remoteFetchData } from "../../repsitory/generic_repository";
import { Meter } from "../../models/meter";
import { fallbackMapCenter, SIMULATED_ALTIOR } from "../../index";
import { AltiorMeterType } from "../../models/altior_meter_type";
import { GeoJSONPoint, geoJsonPointFromCoordinates } from "../../models/geo_json";
import { v1 } from "uuid";
import { subscribeToAction } from "../../store/actions/logs_actions";
import store from "../../store/store";

//  Page state
export type EditMeterPageState = {
  serial: string,
  identifier: string,
  contract: string,
  gps: number[],
  location: string
  status: string,
  deviceTypeId?: string,
  installDate: Date,
  valve: string,
  battery: number,
  currentKey: string,
  description: string,
  addDate: Date,
  lastChanged: Date,
  groups: { value: number, label: string }[]
  fwVersion: string,
  initialReading: string
  rfInterface: string,
  rfConfig: string,
  height?: number,
  loading: boolean,
  error: string,
  validationErrors: string[],
  commandsAvailable: string[],
  updateGroups: string[],
  deleteGroups: string[],
  listGroups: string[],
  coordinates?: GeoJSONPoint
  id?: number,
  pdr: string,
  ldn: string,
  manufacturer: string,
  metrologicalError?: any
  systemError?: any
  uuid?: string
}

export type Property = { name: string, value: string, }


const validate = (state: EditMeterPageState) => {
  let errors: string[] = [];

  if (state.pdr.length === 0) errors = ["Pdr cannot be blank", ...errors]
  if (state.deviceTypeId === undefined) errors = ["Device id cannot be blank", ...errors]

  return errors;
};

//  Page effects
export const createEditMeter = async (state: EditMeterPageState, setState: React.Dispatch<React.SetStateAction<EditMeterPageState>>, history: H.History, intl: Record<string, string>) => {

  const validation = validate(state);

  if (validation.length > 0) {
    setState({ ...state, validationErrors: validation });
    return;
  } else {
    setState({ ...state, loading: true })
  }

  let uuidMap = {};

  if (!state.id) {
    const uuid = v1() + (new Date()).getTime().toString();
    setState({ ...state, uuid })
    uuidMap = { uuid }
    store.dispatch(subscribeToAction("altior_item_creation_channel", uuid));
  }


  const data = {
    "data": {
      ...uuidMap,
      "type": "meter",
      "attributes": {
        "address": state.location,
        "serialNumber": state.serial,
        "contract": state.contract,
        "gps": state.gps,
        "location": state.location,
        "status": state.status,
        "installDate": state.installDate,
        "valve": state.valve,
        "device_type_id": state?.deviceTypeId?.toString(),
        "currentKey": state.currentKey,
        "description": state.description,
        "addDate": state.addDate,
        "lastChanged": state.lastChanged,
        "groups": state.groups,
        "fwVersion": state.fwVersion,
        "initialReading": state.initialReading,
        "rfInterface": state.rfInterface,
        "rfConfig": state.rfConfig,
        "loading": state.loading,
        "height": state.height,
        "error": state.error,
        "validationErrors": state.validationErrors,
        "commandsAvailable": state.commandsAvailable,
        "updateGroups": state.updateGroups,
        "deleteGroups": state.deleteGroups,
        "listGroups": state.listGroups,
        "id": state.id,
        "pdr": state.pdr,
        "ldn": state.ldn,
        "coordinates": state.coordinates,
        "manufacturer": state.manufacturer,
        "latitude": state.gps[0],
        "longitude": state.gps[1]
      }
    }
  };


  try {
    let result;

    if (state.id === undefined) {
      result = await post(data, '/api/v1/meter');
    } else {
      result = await put(data, `/api/v1/meter/${state.id}`);
    }

    console.log(result);

    if (result.errors !== undefined) {
      setState({ ...state, error: JSON.stringify(result), loading: false });
    } else {

      if (state.id) {
        syncGroups(state.id, state, setState, history, intl);
      }

    }
  } catch (e) {
    setState({ ...state, error: e.toString(), loading: false });
  }

  try {
    saveDefaultMeterCoordinates({ lat: state.coordinates?.geometry.coordinates[1]!, lng: state.coordinates?.geometry.coordinates[0]! })
  } catch (e) { console.log(e) }

};

export async function syncGroups(id: string | number, state: EditMeterPageState, setState: React.Dispatch<React.SetStateAction<EditMeterPageState>>, history: H.History, intl: Record<string, string>) {
  const syncGroupResult = await putStatusCode({
    "data": {
      "attributes": {
        "groups_id": state?.groups?.map(g => g.value) ?? []
      },
      "type": "meter"
    }
  }, `/api/v1/meter/${id}/group`)
  if (syncGroupResult !== 204) return setState({ ...state, loading: false, error: intl["cannot-sync-groups"] })
  history.push('/meters');
}


export const seacrhMeter: (pdr: string) => Promise<Meter | string> = async (query: string) => {
  return remoteFetchData<Meter[]>(`/api/v1/meter?query=${query}`)
    .then((m) => m[0])
    .catch(e => {
      return e.toString();
    });
};

export async function fetchMeterTypes(): Promise<AltiorMeterType[] | string> {

  if(SIMULATED_ALTIOR) {
    return [
      {
        name: "name",
        id: 1,
        digitalTwinFields: {class: "MAD:80:7"},
        deviceClass: "",
        category: "meter",
        version: "1"
      }
    ];
  }

  return remoteFetchData<{ attributes: AltiorMeterType, id: string }[]>("/api/v1/altior_meter_type").then((r) => {
    if (typeof r != "string" && typeof r != "undefined") { return r.map(r => ({ ...r.attributes, deviceTypeId: r.id })); }
    return r;
  })
}


export const mkMeterState: (m: Meter) => EditMeterPageState = (meter: Meter) => ({
  addDate: meter.attributes.insertedAt,
  battery: meter?.attributes?.battery || 0,
  commandsAvailable: [],
  contract: meter?.attributes?.contract ?? "--",
  currentKey: meter?.attributes?.currentKey ?? "--",
  description: meter?.attributes?.description ?? "--",
  error: "",
  id: meter.attributes.id,
  deviceTypeId: meter.attributes.deviceTypeId,
  fwVersion: meter?.attributes?.fwVersion ?? '--',
  gps: [meter.attributes.latitude || 45.416668, meter.attributes.longitude || 11.866667],
  groups: meter?.groups?.map(g => ({ value: g.attributes.id!, label: g.attributes.name })),
  initialReading: meter?.attributes?.initialReading ?? '--',
  installDate: meter.attributes.insertedAt,
  lastChanged: meter.attributes.updatedAt,
  loading: false,
  location: meter.attributes.address,
  modelType: meter.attributes.deviceTypeId,
  rfConfig: meter.attributes.rfConfig ?? "--",
  rfInterface: meter.attributes.rfInterface ?? "--",
  serial: meter?.attributes?.serialNumber?.toString() || "",
  status: meter.attributes.state,
  validationErrors: [],
  valve: meter.attributes?.valve ?? "--",
  pdr: meter.attributes.pdr,
  ldn: meter.attributes.ldn,
  height: meter.attributes.height,
  manufacturer: meter.attributes.manufacturer,
  metrologicalError: meter?.attributes?.metrologicalError,
  updateGroups: meter.attributes.updateGroups ?? [],
  deleteGroups: meter.attributes.deleteGroups ?? [],
  listGroups: meter.attributes.listGroups ?? [],
  systemError: meter?.attributes?.systemError,
  identifier: meter.attributes.identifier,
  coordinates: meter.attributes.coordinates ?? geoJsonPointFromCoordinates(getDefaultMeterCoordinates()) ?? {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": [fallbackMapCenter.lng, fallbackMapCenter.lat]
    }
  },
});

export function saveDefaultMeterCoordinates(coords: { lat: number, lng: number }) {
  try {
    window.localStorage.setItem("defualt-meter-coords", JSON.stringify(coords))
  } catch (e) {
    console.log(e);
  }
}

export function getDefaultMeterCoordinates(): { lat: number, lng: number } | null {
  try {
    return JSON.parse(window.localStorage.getItem("defualt-meter-coords")!)
  } catch (e) {
    console.log(e);
    return null;
  }
}

// const a = {
//   "id": "63788140-b306-4422-a5be-14afd85a0227",
//   "type": "postgres",
//   "query": "select sum(v), d as inserted_at from (select max(volume) as v, cast(inserted_at as date) as d from readings group by cast(inserted_at as date), pdr) as m group by d;",
// "config": {
//     "database": "swarm_backend_dev",
//     "hostname": "postgres", "password": "example", "username": "postgres"}
// }
