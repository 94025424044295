import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from "react-router-dom";
import { ErrorComponent } from '../../../components/error_component';
import { emptyMeter, Meter, Attributes } from '../../../models/meter';
import { Leafleet } from '../../../components/leafleet_map/leafleet_map';
import { fallbackMapCenter, history } from '../../..';
import { GeoJSONPoint, geoJsonPointFromCoordinates } from '../../../models/geo_json';
import { gpsFromGeoJsonPoint } from '../../../service/address_from_coordinates';
import { deepCopy, first } from '../../../utils/deep_copy';
import { stat } from 'fs';
import { H4, P, Title } from '../../../components/barbagli/text';
import { TextInput } from '../../../components/barbagli/text_input';
import { AltiorMeterType } from '../../../models/altior_meter_type';
import { createEditMeter, fetchMeterTypes } from '../../meters_page/edit_meter_page_vm';
import Select from 'react-select';
import { gray } from '../../../utils/colors';
import { selectCustomStyles } from '../../../components/barbagli/select';
import { DatePickerInput } from '../../../components/datepicker';
import * as B from '../../../components/barbagli/select';
import { Condominium } from '../../../models/barbagli/condominium';
import { fetchCondominium } from '../../../repsitory/barbagli/condominium_repository';
import { MeterCondominiumView } from './meter_condominium_view';
import { LoaderButton, OutlineButton, } from '../../../components/barbagli/common';
import { confirmAlert } from 'react-confirm-alert';
import { can } from '../../../contexts/auth';
import * as vm from './meter_view_model';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store/store';
import { Log } from '../../../models/log';
import { showMeterBlacklist } from "./meter_blacklist_view";
import { TopTabView } from '../../../components/barbagli/top_tab_view';
import { SelfReadingComponent } from '../../../components/barbagli/self_reading_component';

import { ReadingPage } from '../readings_index/reading_page';

import { ConsumptionsPage } from '../consumptions_index/consumptions_page';
import { MeterHeatDividerFields } from './meter_heat_divider_panel/meter_heat_divider_fields';
import { MeterStateTab } from './meter_state_tab/meter_state_tab';
import { MeterNoteTab } from './meter_note_tab/meter_note_tab';
import { AlarmsIndex } from '../alarms_index/alarms_index';
import { showAlert } from '../../../components/alerts/alert';
import { fetchMeter } from 'repsitory/meter_repository';
import { DynamicMeterFields, tlrAdditionalFields } from './dynamic_meter_fields/dynamic_meter_fields';
import { Switch, withStyles } from '@material-ui/core';
import { LoaderComponent } from 'components/loading_widget';
import { MeterNotificationsPanel } from './meter_notifications/meter_notifications';

type Props = {
  disabled?: boolean
  embedded?: boolean
  meter?: Meter
}

const SwitchGray = withStyles({
  switchBase: {
    color: "#999",
    '&$checked': {
      color: "#666",
    },
    '&$checked + $track': {
      backgroundColor: "#666",
    },
  },
  checked: {},
  track: {},
})(Switch);

export function MeterView(props: Props) {

  const history = useHistory();
  const attributes: Attributes = props.meter?.attributes ?? ((history?.location?.state as Attributes)?.serialNumber ? (history.location.state as Attributes) : emptyMeter().attributes);
  const [state, setState] = useState<Attributes & { error?: string, loading?: boolean, uuid: string | null }>({
    ...deepCopy(attributes),
    uuid: null
  });


  const [altiorMeterTypes, setAltiorMeterTypes] = useState<AltiorMeterType[]>([]);
  const [condominium, setCondominium] = useState<Condominium | null>(null);
  const [deviceTypeSelectValue, setDeviceTypeSelectValue] = useState<{ value: string, label: string }>({ value: state.deviceTypeId ?? "", label: "loading" });
  const alert = useAlert();
  const intl = useIntl();

  const location = useLocation();
  const tab = useMemo(() => parseTopTabViewRoute(location.pathname), [location.pathname])
  const [tabIndex, setTabIndex] = useState(tab ? "Stato" : "Dati")

  useEffect(() => {
    (async () => {
      fetchMeterTypes().then((mt) => {
        if (typeof mt === "string") {
          setState({ ...state, error: mt });
        } else {
          setAltiorMeterTypes(mt);

          const deviceTypeId: string = (state.deviceTypeId ?? first(mt)?.digitalTwinFields?.class ?? "").toString();
          const label = first(mt?.filter((mt) => mt.id.toString() === deviceTypeId) ?? [])?.digitalTwinFields?.class ?? "Caricamento in corso..";

          const l = deviceTypeId !== "" ? label : first(mt?.reverse())?.digitalTwinFields.class ?? label
          const v = deviceTypeId !== "" ? deviceTypeId : first(mt?.reverse())?.id.toString() ?? ""

          setDeviceTypeSelectValue({
            label: l,
            value: v
          })



          setState({
            ...state, deviceTypeId: v,
            class: (l == "Caricamento in corso.." || l == "Errore, Nessun elemento presente") ? state.class : l
          })
        }
      });

      if (!state.condominiumId) return;
      fetchCondominium(state.condominiumId).then((c) => { if (typeof c !== "string") setCondominium(c); })

    })()
  }, [0]);

  useEffect(() => {
    setTimeout(() => {
      if (deviceTypeSelectValue.label === "Caricamento in corso..") {
        setDeviceTypeSelectValue({ ...deviceTypeSelectValue, label: "Errore, Nessun elemento presente" })
      }
    }, 5000)
  }, [deviceTypeSelectValue.label])


  useEffect(() => {
    console.log(state.class)
    if ((state.class == "TLR:100:7" || state.class == "TLR:26:7") && !state?.meta?.additional_fields) {
      setState({ ...state, meta: { ...state.meta, additional_fields: tlrAdditionalFields } })
    } else if (state.class !== "TLR:100:7" && state.class !== "TLR:26:7") {
      setState({ ...state, meta: { ...state.meta, additional_fields: undefined } })
    }
  }, [state.class])

  const [loading, setLoading] = useState(false);

  if (state.error && state.error !== "") return <ErrorComponent message={state.error} />;
  if (state.loading) return <p>Loading</p>;

  const page = (
    <>
      {condominium && !props.embedded && <>
        <MeterCondominiumView
          condominium={condominium}
          subCondominiumId={state.subCondominiumId}
          immobileId={state.immobileId}
        />
      </>}

      <div className="row mt-4">
        <div className="col-md-6">
          <div className={"row "}>
            <div className="col-md-12">
              <Title>{intl.messages["geolocation"]}</Title>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <TextInput
                enabled={!props.disabled}
                label={intl.messages["address"].toString()}
                value={state.address}
                onChange={(s) => setState({ ...state, address: s })}
              />
              {loading && <div style={{ marginTop: 30 }}><LoaderComponent width={24} height={24} /></div>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInput
                type={"number"}
                enabled={!props.disabled}
                value={state?.coordinates?.geometry?.coordinates[1]?.toString() ?? fallbackMapCenter.lat.toString()}
                onChange={(s) => {
                  if (state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [state.coordinates.geometry.coordinates[0], Number(s)]
                      }
                    };
                    setState({ ...state, coordinates })
                  }
                }}
                label={intl.messages["lat"].toString().toUpperCase()}
              />

            </div>
            <div className="col-md-4">
              <TextInput
                type={"number"}
                enabled={!props.disabled}
                value={state?.coordinates?.geometry?.coordinates[0]?.toString() ?? fallbackMapCenter.lng.toString()}
                onChange={(s) => {
                  if (state.coordinates) {
                    const coordinates = {
                      ...state.coordinates,
                      geometry: {
                        ...state.coordinates.geometry,
                        coordinates: [Number(s), state.coordinates.geometry.coordinates[1]]
                      }
                    };
                    setState({ ...state, coordinates })
                  }
                }}
                label={intl.messages["lng"].toString().toUpperCase()}
              />

            </div>

            <div className="col-md-4">
              <TextInput
                label={intl.messages["height"].toString().toUpperCase()}
                enabled={!props.disabled}
                value={state?.height?.toString() || "0"}
                type={"number"}
                onChange={(evt) => {
                  setState({ ...state, height: Number(evt) })
                }}
              />
            </div>

          </div>
        </div>
        <div className="col-md-6">
          <Leafleet
            key={JSON.stringify(state.coordinates)}
            height={186}
            map_id={"meters-create"}
            editableItem={state.coordinates ?? geoJsonPointFromCoordinates(fallbackMapCenter) ?? undefined}
            fallbackMapCenter={fallbackMapCenter}
            onEdit={async (dot) => {

              if (dot && !props.disabled) {
                setState({ ...state, coordinates: dot as GeoJSONPoint, address: await gpsFromGeoJsonPoint(dot as GeoJSONPoint) });
              }

            }} />
        </div>
      </div>


      <div className="row mt-4">
        <div className="col-md-12">
          <Title> {intl.messages["data"]}</Title>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4" style={{ marginTop: -10 }}>
          <TextInput
            value={state.serialNumber ?? ""}
            enabled={!state.id && !props.disabled}
            label={intl.messages["serial"].toString().toUpperCase()}
            onChange={(s) => { setState({ ...state, serialNumber: s }) }}
          />
        </div>
        <div className="col-md-4">
          <DatePickerInput
            enabled={!state.id && !props.disabled}
            label={intl.messages["install_date"].toString().toUpperCase()}
            value={new Date(state.installDate ?? (new Date()).toISOString())}
            onChange={(d) => { setState({ ...state, installDate: d.toISOString() }) }}
          />
        </div>
        <div className="col-md-4">
          <B.Select
            enabled={!props.disabled}
            label={intl.messages["meter_type"].toString().toUpperCase()}
            isMulti={false}
            valueMapper={(v) => v.replace("\"", "")}
            availableOptions={["AF", "RIPARTITORE", "ACS", "CALORIE / FRIGORIE"]}
            selectedOptions={[state.type]}

            onChange={([type]) => {
              return setState({ ...state, type });
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <H4 style={{ marginTop: 4 }}>{intl.messages["class"].toString().toUpperCase()}</H4>
          <Select
            value={deviceTypeSelectValue}
            isDisabled={state.id !== undefined || props.disabled}
            onChange={(g: any) => {
              if (g.label == "Caricamento in corso.." || g.label == "Errore, Nessun elemento presente") {

              } else {
                setState({ ...state, deviceTypeId: g.value, class: g.label });
                setDeviceTypeSelectValue(g);
              }


            }}
            isMulti={false}
            options={altiorMeterTypes?.map((g: any) => ({ value: g.deviceTypeId, label: g.digitalTwinFields.class }))}
            theme={(t) => ({ ...t, colors: { ...t.colors, primary: gray, primary25: "rgba(0, 0, 0, 0.05)", neutral5: "#ccc" } })}
            name=""
            styles={selectCustomStyles}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="col-md-4" style={{ marginTop: -4 }}>
          <TextInput
            enabled={(!state.id && !props.disabled) || (attributes.mechanicalSerialNumber ?? "") === ""}
            label={intl.messages["mechanical_serial_number"].toString().toUpperCase()}
            value={state.mechanicalSerialNumber}
            onChange={(v) => { setState({ ...state, mechanicalSerialNumber: v }) }}
          />
        </div>


      </div>

      <div className="row">
        <div className="col-md-8">
          <TextInput
            label={intl.messages["description"].toString().toUpperCase()}
            value={state.description}
            enabled={!props.disabled}
            onChange={(v) => { setState({ ...state, description: v }) }}
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label={intl.messages["active_key"].toString().toUpperCase()}
            value={state.currentKey}
            enabled={!state.id && !props.disabled}
            onChange={(v) => { setState({ ...state, currentKey: v }) }}
          />
        </div>
      </div>

      <div className="row">
        {state.id && state.identifier && <div className="col-md-4">
          <TextInput
            label={intl.messages["identifier"].toString().toUpperCase()}
            value={state.identifier}
            enabled={false}
            onChange={console.log}
          />
        </div>}

        <div className="col-md-4">
          <H4>{intl.messages["absolute_consumption"].toString().toUpperCase()}</H4>
          <SwitchGray
            checked={state?.meta?.absolute_consumption ?? false}
            color={"primary"}
            onChange={(e) => {
              e.preventDefault();
              setState({ ...state, meta: { ...(state.meta ?? {}), absolute_consumption: e.target.checked ?? false } })
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>

      </div>

      {state.meta?.additional_fields && <DynamicMeterFields key={`add${state.class}`} attributes={state} intl={intl.messages as any} onUpdate={(a: Attributes) => setState({ ...state, ...a })} />}

      {state.type.toLowerCase() === "ripartitore" &&
        <MeterHeatDividerFields
          intl={intl.messages as any}
          attributes={state}
          onUpdate={(hd) => setState({ ...state, meterHeatDivider: hd })}
        />}

      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-end">
          {!props.embedded && <OutlineButton style={{ marginRight: 12 }} onClick={() => { history.push("/meters") }}>
            {intl.messages["close"]}
          </OutlineButton>}

          {state.id && !props.embedded && <LoaderButton
            primary={false}
            style={{ marginRight: 12 }}
            onClick={() => {
              return new Promise((resolve, reject) => {
                confirmAlert({
                  title: intl.messages["confirm"].toString(),
                  message: intl.messages["meter_deletion_confirm"].toString(),
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: async () => {
                        // @ts-ignore
                        if (typeof await vm.deleteMeter(state) != "string") {
                          history.push("/meters");
                          alert.show(intl.messages["delete_success"])
                          resolve(true)
                        } else {
                          alert.show(intl.messages["operation_error"])
                          resolve(intl.messages["cannot_delete"].toString())
                        }
                      }
                    },
                    {
                      label: 'No',
                      onClick: () => { }
                    }
                  ]
                });
              })

            }}>{intl.messages["delete"]}</LoaderButton>}

          {(state.id && can("edit", attributes)) && <OutlineButton onClick={() => showMeterBlacklist(intl.messages as any, state)} style={{ marginRight: 12 }}>{intl.messages["alarms_visibility"]}</OutlineButton>}

          {((state.id && can("edit", attributes)) || !state.id) && !props.disabled &&
            <LoaderButton disableSuccess
              // @ts-ignore
              onClick={async () => {

                const validation_result = vm.validate(state);
                if (validation_result.length > 0) {
                  const msg = validation_result.map(vr => intl.messages[vr]?.toString() ?? vr).join(" ");
                  alert.error(msg);
                  return msg;
                }

                let result: string | true;
                if (state.meterHeatDivider && state.id) {

                  if (vm.is_editing_mapping(attributes, state)) {
                    showAlert("Attenzione", "Questa azione invaliderà i consumi precedenti alla data specificata e ricalcolerà i successivi, procedere?",
                      {
                        onDone: async () => {
                          result = await vm.createEditMeter(state, setState);
                          if (typeof result === "string") {
                            alert.error(intl.messages["operation_error"] + result)
                          } else {
                            if (state.id) alert.show(intl.messages["update_success"])
                            else alert.show(intl.messages["creation_success"])
                            if (!props.embedded) history.push("/meters")
                          }
                        }
                      }
                    )
                    return true as boolean;
                  } else {
                    const s = deepCopy(state)
                    delete s["meterHeatDivider"];
                    result = await vm.createEditMeter(s, setState);
                  }

                } else {
                  result = await vm.createEditMeter(state, setState);
                }

                if (typeof result === "string") {
                  alert.error(intl.messages["operation_error"] + result)
                } else {
                  if (state.id) alert.show(intl.messages["update_success"])
                  else alert.show(intl.messages["creation_success"])
                  if (!props.embedded) history.push("/meters")
                }
                return result;
              }}>
              {intl.messages["save"]}
            </LoaderButton>
          }
        </div>
      </div>
    </>
  );

  if (props.embedded || !state.id) return page;



  return <div style={{ margin: -30 }}>
    <TopTabView
      selectedTab={tabIndex as any}
      onTabChange={setTabIndex}

      children={
        [
          [
            "Dati",
            page
          ],
          [
            "Autolettura",
            <SelfReadingComponent intl={intl.messages as any} meter={state} />
          ],
          [
            intl.messages["readings"].toString(),
            <ReadingPage condominium={condominium as Condominium} selectedNode={{ attributes: state, id: state.id.toString(), groups: [], availableTabs: [], path_url: "", children: [], node_id: state.id.toString(), type: "METER" } as any} />
          ],
          ["Allarmi", <AlarmsIndex prexistingFilters={[{ column: "meter_identifier", value: state.identifier }]} />],
          [
            intl.messages["consumption"].toString(),
            <ConsumptionsPage condominium={condominium as Condominium} selectedNode={{ attributes: state, id: state.id.toString(), groups: [], availableTabs: [], path_url: "", children: [], node_id: state.id.toString(), type: "METER" } as any} />
          ],
          [
            intl.messages["state"].toString(),
            <MeterStateTab attributes={state} />
          ],
          [
            intl.messages["notifications"].toString(),
            <MeterNotificationsPanel attributes={state} onMeterUpdate={(a) => {
              console.log(state);
              setState({ ...state, ...a });
              console.log({ ...state, ...a })
              const result = vm.createEditMeter({ ...state, ...a }, setState)
              if (typeof result === "string") {
                alert.error(intl.messages["operation_error"] + result)
              } else {
                if (state.id) alert.show(intl.messages["update_success"])
                else alert.show(intl.messages["creation_success"])

              }
            }} />
          ],
          [
            intl.messages["note"].toString(),
            <MeterNoteTab attributes={state} />
          ],
        ]
      } />
  </div>
}


function parseTopTabViewRoute(r: string): string | undefined {
  const [pairs, _] = r.split("/").filter(i => i !== "").reduce((acc: any, x: string) => {
    // console.log(acc)
    if (acc === []) return [[], x];
    const [pairs, v] = acc;
    if (v) return [[...pairs || [], [v, x]], undefined]
    return [pairs, x]
  }, [])
  const tab = first(pairs.filter(([k, _]: [string, string]) => k === "tab").map(([_, v]: [string, string]) => v));
  if (tab) return tab as string;
  return undefined;
}

